var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"550","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.showConfirmationDialog)?_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Confirmation")]),_c('v-card-text',[_c('v-card',[_c('v-card-text',[_c('p',[_vm._v(_vm._s(_vm.$t('msg.score_generated_text_1')))]),_c('p',[_vm._v(_vm._s(_vm.$t('msg.score_generated_text_2')))]),_c('v-card-actions',[_c('v-btn',{staticClass:"primary",on:{"click":_vm.destroy}},[_vm._v(_vm._s(_vm.$t('cancel')))]),_c('v-spacer'),_c('v-btn',{staticClass:"primary",on:{"click":_vm.hideConfirmationDialog}},[_vm._v(_vm._s(_vm.$t('confirm')))])],1)],1)],1)],1)],1):_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t('msg.edit_customer_info')))]),_c('v-card-text',[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failedRules = ref.failedRules;
var failed = ref.failed;
var passes = ref.passes;
return [_c('form',[_c('v-skeleton-loader',{attrs:{"loading":_vm.loading,"transition":"scale-transition","type":"list-item-two-line"}},[_c('ValidationProvider',{attrs:{"rules":{required: true, min : 1, max : 20, regex:/^[a-zA-Z0-9]+$/ }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return [_c('v-text-field',{attrs:{"error-messages":_vm.$translatedErrorMessages(failedRules,'customer_id'),"readonly":"","disabled":"","label":_vm.$t('lbl.customer_id')},model:{value:(_vm.customer.customerId),callback:function ($$v) {_vm.$set(_vm.customer, "customerId", $$v)},expression:"customer.customerId"}})]}}],null,true)})],1),_c('v-skeleton-loader',{attrs:{"loading":_vm.loading,"transition":"scale-transition","type":"list-item-two-line"}},[_c('ValidationProvider',{attrs:{"rules":{required: true, min : 1, max : 10, regex:/^[a-zA-Z0-9]+$/ }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return [_c('v-text-field',{staticClass:"requiredSticker",attrs:{"error-messages":_vm.$translatedErrorMessages(failedRules,'applicationNumber'),"label":_vm.$t('lbl.application_id')},model:{value:(_vm.customer.applicationNumber),callback:function ($$v) {_vm.$set(_vm.customer, "applicationNumber", $$v)},expression:"customer.applicationNumber"}})]}}],null,true)})],1),_c('v-skeleton-loader',{attrs:{"loading":_vm.loading,"transition":"scale-transition","type":"list-item-two-line"}},[_c('ValidationProvider',{attrs:{"rules":{required : true},"name":"gender"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return [_c('v-autocomplete',{staticClass:"requiredSticker",attrs:{"error-messages":_vm.$translatedErrorMessages(failedRules,'gender'),"items":_vm.dropdown.genders,"item-text":"text","item-value":"value","label":_vm.$t('lbl.gender')},model:{value:(_vm.customer.gender),callback:function ($$v) {_vm.$set(_vm.customer, "gender", $$v)},expression:"customer.gender"}})]}}],null,true)})],1),_c('v-skeleton-loader',{attrs:{"loading":_vm.loading,"transition":"scale-transition","type":"list-item-two-line"}},[_c('ValidationProvider',{attrs:{"rules":{required : false, min:2, max:100, regex:/^[a-zA-Z0-9%.&(),/'\s-]+$/ }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return [_c('v-text-field',{attrs:{"error-messages":_vm.$translatedErrorMessages(failedRules,'position'),"label":_vm.$t('lbl.position')},model:{value:(_vm.customer.designation),callback:function ($$v) {_vm.$set(_vm.customer, "designation", $$v)},expression:"customer.designation"}})]}}],null,true)})],1),_c('v-skeleton-loader',{attrs:{"loading":_vm.loading,"transition":"scale-transition","type":"list-item-two-line"}},[_c('ValidationProvider',{attrs:{"rules":{required: true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return [_c('v-text-field',{attrs:{"error-messages":_vm.$translatedErrorMessages(failedRules,'branch'),"disabled":"","label":_vm.$t('lbl.branch')},model:{value:(_vm.customer.branch),callback:function ($$v) {_vm.$set(_vm.customer, "branch", $$v)},expression:"customer.branch"}})]}}],null,true)})],1),_c('v-skeleton-loader',{attrs:{"loading":_vm.loading,"transition":"scale-transition","type":"list-item-two-line"}},[_c('ValidationProvider',{attrs:{"rules":{required : true},"name":"product"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return [_c('v-autocomplete',{staticClass:"requiredSticker",attrs:{"error-messages":_vm.$translatedErrorMessages(failedRules,'product'),"items":_vm.$parent.filters.scheme,"item-text":"label","item-value":"code","label":_vm.$t('lbl.product_applied')},on:{"change":_vm.handleProductChange},model:{value:(_vm.customer.productApplied),callback:function ($$v) {_vm.$set(_vm.customer, "productApplied", $$v)},expression:"customer.productApplied"}})]}}],null,true)})],1),_c('v-skeleton-loader',{attrs:{"loading":_vm.loading,"transition":"scale-transition","type":"list-item-two-line"}},[_c('ValidationProvider',{attrs:{"rules":{required : true},"name":"businessCategory"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return [_c('v-autocomplete',{staticClass:"requiredSticker",attrs:{"error-messages":_vm.$translatedErrorMessages(failedRules,'business_category'),"items":_vm.dropdown.businessCategories,"item-text":"text","item-value":"value","label":_vm.$t('lbl.business_category')},model:{value:(_vm.customer.businessCategory),callback:function ($$v) {_vm.$set(_vm.customer, "businessCategory", $$v)},expression:"customer.businessCategory"}})]}}],null,true)})],1),_c('v-skeleton-loader',{attrs:{"loading":_vm.loading,"transition":"scale-transition","type":"list-item-two-line"}},[_c('ValidationProvider',{attrs:{"rules":{ required: false, numeric: true, min_value: 1, max_value:10000}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return [_c('v-text-field',{attrs:{"error-messages":_vm.$translatedErrorMessages(failedRules,'employeesCount'),"label":_vm.$t('lbl.business_size')},model:{value:(_vm.customer.employeesCount),callback:function ($$v) {_vm.$set(_vm.customer, "employeesCount", $$v)},expression:"customer.employeesCount"}})]}}],null,true)})],1),_c('v-skeleton-loader',{attrs:{"loading":_vm.loading,"transition":"scale-transition","type":"list-item-two-line"}},[_c('ValidationProvider',{attrs:{"rules":{ required: true, numeric: true, min_value: _vm.selectedProduct.validation.minAmount, max_value : _vm.selectedProduct.validation.maxAmount}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return [_c('v-text-field',{staticClass:"requiredSticker",attrs:{"error-messages":_vm.$translatedErrorMessages(failedRules,'financingAmount', [_vm.currency, _vm.selectedProduct.validation.minAmount, _vm.selectedProduct.validation.maxAmount]),"label":_vm.$t('lbl.financing_amount_applied'),"prefix":_vm.currency},model:{value:(_vm.customer.loanAmount),callback:function ($$v) {_vm.$set(_vm.customer, "loanAmount", $$v)},expression:"customer.loanAmount"}})]}}],null,true)})],1),_c('v-skeleton-loader',{attrs:{"loading":_vm.loading,"transition":"scale-transition","type":"list-item-two-line"}},[_c('ValidationProvider',{attrs:{"rules":{ required: true, numeric: true, min_value: _vm.selectedProduct.validation.minTenure, max_value : _vm.selectedProduct.validation.maxTenure}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return [_c('v-text-field',{staticClass:"requiredSticker",attrs:{"error-messages":_vm.$translatedErrorMessages(failedRules,'tenureApplied', [_vm.selectedProduct.validation.minTenure, _vm.selectedProduct.validation.maxTenure]),"label":_vm.$t('lbl.financing_tenure_applied'),"required":"","suffix":"Months"},model:{value:(_vm.customer.tenureApplied),callback:function ($$v) {_vm.$set(_vm.customer, "tenureApplied", $$v)},expression:"customer.tenureApplied"}})]}}],null,true)})],1),_c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(failed),expression:"failed"}]},[_c('p',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(_vm.$t('msg.failed_action')))])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.saveError),expression:"saveError"}]},[_c('p',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(_vm.$t('msg.failed_action')))])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.serverError),expression:"serverError"}]},[_c('p',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(_vm.$t('error.server')))])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.informationTampering),expression:"informationTampering"}]},[_c('p',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(_vm.$t('error.information_tampering')))])])]),_c('v-card-actions',{attrs:{"align":"right"}},[_c('v-spacer'),_c('v-skeleton-loader',{staticClass:"mr-3",attrs:{"loading":_vm.loading||_vm.sending,"transition":"scale-transition","type":"button"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.destroy}},[_vm._v(_vm._s(_vm.$t('cancel')))])],1),_c('v-skeleton-loader',{attrs:{"loading":_vm.loading||_vm.sending,"transition":"scale-transition","type":"button"}},[_c('v-btn',{attrs:{"color":"primary","dark":""},on:{"click":function($event){return passes(_vm.saveCustomer)}}},[_vm._v(_vm._s(_vm.$t('save'))+" ")])],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }